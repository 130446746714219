@use "variables" as var;

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body,
#root,
.page-container {
  height: 100vh;
  height: -webkit-fill-available;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: var.$font-family-main;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

// Visually hide element but leave it accessible for screen readers
// https://www.a11ywithlindsey.com/blog/introduction-accessible-labeling
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
