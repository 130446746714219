@use "../../scss/variables.scss" as var;

.notifications {
  padding: 1.5em 1em 0 1em;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;
  max-height: -webkit-fill-available;
}

.notifications__header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1.5em 0;

  &-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-title {
    font-weight: var.$font-weight-bold;
    color: var.$clr-blackblue;
    font-size: 1.25rem;
    line-height: 28px;
  }

  &-btn {
    font-family: var.$font-family-main;
    font-weight: var.$font-weight-normal;
    color: var.$clr-greyblue-400;
    background: 0;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 0.875rem;

    &:hover {
      color: var.$clr-blue;
    }
  }
}

.notifications__list {
  overflow: auto;
}

.notifications__unread-count {
  $curved-border: 6px;

  background: var.$clr-blue;
  color: var.$clr-white-lighter;
  padding: 0.1em 0.2em 0.2em 0.2em;
  min-width: 2.75ch;
  text-align: center;
  margin: 0 0 0 0.7em;
  border-radius: $curved-border;
  font-size: 1rem;
  font-weight: var.$font-weight-bold;
}

.notifications__list {
  padding-inline: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

@media (min-width: var.$breakpoint-desktop) {
  .notifications {
    $curved-border: 15px;

    padding: 2.25em 2.25em 0 2.25em;
    box-shadow: 0px 20px 60px rgba(73, 97, 168, 0.05);
    background-color: var.$clr-white-lighter;
    margin: 0 0 2.25em 0;
    max-width: 46rem;
    max-height: 52.5rem;
    border-radius: $curved-border;
  }

  .notifications__list {
    padding: 0 0 0.75em 0;
  }

  .notifications__header {
    margin: 0 0 1.75em 0;

    &-title {
      font-size: 1.5rem;
    }

    &-btn {
      font-size: 1rem;
    }
  }
}
