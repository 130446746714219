@use "scss/variables" as var;
@use "scss/global";
@use "scss/mediaqry";

@media (min-width: var.$breakpoint-desktop) {
  body {
    background-color: var.$clr-white;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
